import styles from '../OpeningHoursTable.module.scss';
import { Small } from 'src/features/shared/components/typography';

interface IExceptionProps {
  day: string;
  text: string;
}

export const Exception = ({ day, text }: IExceptionProps) => (
  <li className={styles.item}>
    <Small className={styles.capitalize}>{day}</Small>
    <Small>{text}</Small>
  </li>
);
