import NextImage, { ImageLoader, ImageProps } from 'next/image';

interface ContentfulImageProps extends Omit<ImageProps, 'loader'> {}

const loader: ImageLoader = ({ src, width, quality }) =>
  `https:${src}?fm=webp&w=${width}&q=${quality ?? 80}`;

export function ContentfulImage(props: ContentfulImageProps) {
  return <NextImage loader={loader} {...props} />;
}
