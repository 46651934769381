/*
    This is a list of all 6-month one off contracts ids,
    for which there is a bug in PerfectGym that returns 2 membership charges as opposed to one,
    meaning we show the wrong price unless we make an exception
 */
const SixMonthOneOffContractIdsProduction = [
  4477, 5148, 6381, 4459, 35103, 35107, 10483, 10490, 10497, 10504, 8588, 10602,
  10597, 10591,
];
const SixMonthOneOffContractIdsTest = [
  4477, 5148, 6381, 4459, 35103, 35107, 10483, 10490, 10497, 10504, 8588, 10602,
  10597,
];

export const SixMonthOneOffContractIds = {
  production: SixMonthOneOffContractIdsProduction,
  test: SixMonthOneOffContractIdsTest,
};
