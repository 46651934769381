import classNames from 'classnames'
import { ComponentProps } from 'react'
import styles from './index.module.scss'

interface ILinkItemProps extends ComponentProps<'div'> {
  isFullScreen?: boolean
  height?: number
}
export function LinkItem({
  children,
  isFullScreen,
  height,
  className,
  ...props
}: ILinkItemProps) {
  return (
    <div
      style={height ? { height: `${height}px` } : {}}
      className={classNames(
        styles.link,
        {
          [styles.link__fullscreen]: isFullScreen
        },
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}
