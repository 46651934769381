import { getContractByPaymentPlanId } from '@features/pg-funnel/domains/contracts/selectors';
import useDataLayer from '@features/pg-funnel/hooks/use-datalayer';
import useFormPaging from '@features/pg-funnel/hooks/use-form-paging';
import useFunnelConfig from '@features/pg-funnel/hooks/use-funnel-config';
import { getMembershipPrices } from '@features/pg-funnel/hooks/use-membership-prices';
import { mapFormData } from '@features/pg-funnel/services/perfect-gym/mappers/map-form-data';
import { postSignup } from '@features/pg-funnel/services/proxy';
import * as Selectors from '@features/pg-funnel/store/selectors';
import { FormValues } from '@features/pg-funnel/types';
import { getAnalyticsQueries } from '@features/pg-funnel/utils/analytics';
import * as SessionStorage from '@features/pg-funnel/utils/session-storage';
import { useParams, useRouter } from 'next/navigation';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import { assertIsString } from 'src/utils/assertions/assertIsString';

export const useFunnel = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { slug: funnelSlug } = useParams<{ slug: string }>();
  const { push } = useRouter();

  assertIsString(funnelSlug);

  const clubsById = useSelector(Selectors.clubsByIdSelector(funnelSlug));
  const { config } = useFunnelConfig();
  const selectedPaymentPlan = useSelector(
    Selectors.selectedPaymentPlanSelector(funnelSlug),
  );
  const selectedContract = useSelector(
    getContractByPaymentPlanId(funnelSlug, selectedPaymentPlan?.id.toString()),
  );

  const activeVoucher = useSelector(
    Selectors.activeVoucherSelector(funnelSlug),
  );
  const skipPaymentValidation =
    activeVoucher?.summary?.membership?.total === 0 ||
    selectedPaymentPlan?.membershipFee === 0;

  const { pushToDataLayerOnSubmit } = useDataLayer();
  const { currentStepIndex, goStepNext } = useFormPaging();

  const postForm = async (formValues: FormValues) => {
    setIsSubmitting(true);
    if (!selectedPaymentPlan) {
      throw new Error('No selected payment plan');
    }

    try {
      // send a begin_checkout event to Google Analytics
      pushToDataLayerOnSubmit &&
        pushToDataLayerOnSubmit(
          funnelSlug,
          formValues,
          selectedPaymentPlan,
          selectedContract,
          activeVoucher,
        );

      const prices = getMembershipPrices(
        funnelSlug,
        selectedPaymentPlan,
        selectedContract,
        activeVoucher,
      );
      // gather additional data into an analytics query that we send to Electrolyte, so they can make a new call to GA once a member is confirmed
      const analyticsQueries = getAnalyticsQueries({
        selectedPaymentPlan,
        voucher: activeVoucher,
        prices,
        funnelSlug,
        formValues,
        club: formValues.clubId ? clubsById[formValues.clubId] : undefined,
      });

      const signUpData = mapFormData(
        formValues,
        selectedPaymentPlan,
        funnelSlug,
        activeVoucher,
        skipPaymentValidation,
        analyticsQueries,
      );

      pushGA4Event(GA4_EVENTS.submitFunnel, {
        first_name: formValues.firstName,
        last_name: formValues.lastName,
        email_address: formValues.emailAddress,
        phone_number: formValues.phoneNumber,
        postal_code: formValues.postalCode,
        city: formValues.city,
      });

      const { data, statusCode } = await postSignup(signUpData);
      // Remove values from session storage after successful sign up
      if (statusCode !== 200) {
        return Promise.resolve({ ...data?.error });
      }

      setIsSubmitting(false);

      window.location.href =
        data?.paymentLink ?? `/payment?status=failed&funnel=${funnelSlug}`;
    } catch (error) {
      await push(`/inschrijving-mislukt?funnel=${funnelSlug}`);
    }
  };

  const handleSubmit = async (formValues: FormValues) => {
    SessionStorage.setItem('validUntil', (currentStepIndex + 1).toString());

    if (currentStepIndex + 1 === config.steps.length) {
      return await postForm(formValues);
    }

    goStepNext();
    return null;
  };

  return {
    selectedPaymentPlan,
    handleSubmit,
    clubsById,
    isSubmitting,
    skipPaymentValidation,
  };
};
