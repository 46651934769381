import { useIntl } from 'src/domains/i18n';
import styles from './OpeningHoursTable.module.scss';
import {
  OpeningHoursException,
  OpeningHoursItem,
} from 'src/features/checkout/types/checkout';
import { Body, FontWeight } from '../typography';
import { Exception } from './exception';
import { Item } from './item';
import { isFuture } from 'src/utils/dates';
import moment from 'moment';

interface OpeningHoursTableProps {
  openingDate: string;
  openingHours?: OpeningHoursItem[];
  openingHoursExceptions?: OpeningHoursException[];
}

const DAY_MAP = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export function OpeningHoursTable({
  openingHours,
  openingHoursExceptions,
  openingDate,
}: OpeningHoursTableProps) {
  const { formatMessage } = useIntl();

  const exceptions: { day: string; text: string }[] =
    openingHoursExceptions?.map((item) => ({
      day: item.day,
      text: item.isClosed
        ? formatMessage('club-detail.opening-hours.closed')
        : item.hours,
    })) || [];

  if (isFuture(openingDate)) {
    exceptions.push({
      day: moment(openingDate).format('dddd D MMM').toLowerCase(),
      text: formatMessage('club-detail.opening-hours.opening-soon.table.text'),
    });
  }

  return (
    <>
      <ul className={styles.openingHoursList}>
        {openingHours?.map((item) => (
          <Item
            key={item.day}
            isClosed={item.isClosed}
            hours={item.hours}
            day={formatMessage(`general.days.${item.day}`)}
            closedText={formatMessage('club-detail.opening-hours.closed')}
            isToday={DAY_MAP[new Date().getDay() - 1] === item.day}
          />
        ))}
      </ul>

      {exceptions?.length ? (
        <div className={styles.innerContainer}>
          <Body fontWeight={FontWeight.Bold}>
            {formatMessage('club-detail.opening-hours.exception-title')}
          </Body>
          <ul className={styles.openingHoursExceptionsList}>
            {exceptions.map((item) => (
              <Exception key={item.day} {...item} />
            ))}
          </ul>
        </div>
      ) : null}
    </>
  );
}
