export function getAnalyticsClientId() {
  const gaPattern = 'GA1.2.'
  const cookieName = '_ga='
  const analyticsClientId = document?.cookie
    ?.split('; ')
    .find((row) => row.startsWith(cookieName))
    ?.split('=')[1]
    ?.substring(gaPattern.length)

  return analyticsClientId
}
