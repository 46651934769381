'use client';
import styles from './AboutClub.module.scss';
import { useIntl } from '@domains/i18n';
import { Anchor } from '@features/club-page/components/club-details/anchor-links/anchor';
import { IClubAttributeFields } from '@features/pg-funnel/services/contentful/types/generated/contentful';
import classNames from 'classnames';
import { EntryFields } from 'contentful';
import Icons from 'features/shared/components/icons';
import { useState } from 'react';
import {
  Body,
  ClubPageSectionHeading,
  FontWeight,
  Large,
} from 'src/features/shared/components/typography';
import { useRichTextRenderer } from 'src/features/shared/hooks/useRichTextRenderer';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import { HighlightedAttributes } from './highlighted-attributes';

interface IAboutClubProps {
  description: EntryFields.RichText;
  highlightedAttributes: IClubAttributeFields[];
  additionalDescription?: EntryFields.RichText;
  hasClubOffers: boolean;
  isPanel: boolean;
}

export const AboutClub = ({
  description,
  additionalDescription,
  highlightedAttributes,
  hasClubOffers,
  isPanel,
}: IAboutClubProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { formatMessage } = useIntl();
  const { renderRichText } = useRichTextRenderer();

  const ExpandIcon = isExpanded ? Icons.Minus : Icons.Plus;

  const handleToggleIsExpanded = () => {
    if (!isExpanded) {
      pushGA4Event(GA4_EVENTS.trackEvent, {
        event_name: 'click_read_more_club',
      });
    }
    setIsExpanded(!isExpanded);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleToggleIsExpanded();
    }
  };

  return (
    <div
      className={classNames(styles.aboutContainer, isPanel && styles.isPanel)}
    >
      <Anchor title={formatMessage('club-detail.about.title')} />

      <ClubPageSectionHeading forceMobileView={isPanel}>
        {formatMessage('club-detail.about.title')}
      </ClubPageSectionHeading>

      <Large
        component="div"
        className={classNames(styles.description, isPanel && styles.isPanel)}
      >
        <>{renderRichText(description)}</>
      </Large>

      {additionalDescription && (
        <>
          {isExpanded && (
            <Large component="div" className={styles.expandedDescription}>
              <>{renderRichText(additionalDescription)}</>
            </Large>
          )}

          <div
            tabIndex={0}
            className={styles.toggle}
            onClick={handleToggleIsExpanded}
            onKeyPress={handleKeyPress}
          >
            <ExpandIcon className={styles['toggle-icon']} />
            <Body fontWeight={FontWeight.Bold}>
              {isExpanded
                ? formatMessage('club-detail.about.hide')
                : formatMessage('club-detail.about.show-more')}
            </Body>
          </div>
        </>
      )}

      {highlightedAttributes ? (
        <HighlightedAttributes
          attributes={highlightedAttributes}
          hasClubOffers={hasClubOffers}
        />
      ) : null}
    </div>
  );
};
