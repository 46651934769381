import { useRouter, useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

import useFunnelConfig from '@features/pg-funnel/hooks/use-funnel-config';
import {
  ContentTypes,
  Step as IStep,
} from '@features/pg-funnel/hooks/use-funnel-config/types';
import * as SessionStorage from '@features/pg-funnel/utils/session-storage';
import { useFormikContext } from 'formik';
import { FormValues } from 'src/features/pg-funnel/types';
import { getFunnelFormId } from '../utils/getFunnelFormId';

export default function useFormPaging() {
  const router = useRouter();
  const {
    config: { steps, includeAccessCode },
  } = useFunnelConfig();
  const formik = useFormikContext<FormValues>();
  const funnelSlug = useSearchParams().get('slug');
  const stepSlug = useSearchParams().get('step');
  const clubId = useSearchParams().get('clubId');
  const migrated = useSearchParams().get('migrated');

  const formId = getFunnelFormId(funnelSlug ?? '');

  const stepsBySlug = steps.reduce<{ [key: string]: IStep }>(
    (acc, step) => ({
      ...acc,
      [step.slug]: step,
    }),
    {},
  );

  const goStepNext = () => {
    router.push(`/funnel/${funnelSlug}/${steps[currentStepIndex + 1].slug}`);
  };

  const goStepBack = () => {
    router.push(`/funnel/${funnelSlug}/${steps[currentStepIndex - 1].slug}`);
  };

  const currentStep = stepsBySlug[stepSlug as string];
  const currentStepIndex = steps.findIndex((step) => step.slug === stepSlug);
  const totalSteps = steps.length;

  const clubSelectStep = steps.filter((step) =>
    step.contentItems?.find(
      (item) => item.type === ContentTypes.FUNNEL_CLUB_SEARCH,
    ),
  )[0];
  const clubSelectStepIndex = clubSelectStep && steps.indexOf(clubSelectStep);
  const isClubSelectStep = currentStepIndex === clubSelectStepIndex;
  const isStepAfterClubSelect = currentStepIndex === clubSelectStepIndex + 1;
  const isAccessCodeStep = includeAccessCode && currentStepIndex === 0; // Access code should always be first step

  const progress = useMemo(() => {
    return {
      current: currentStepIndex + 1,
      total: totalSteps,
      isLastStep: currentStepIndex + 1 === totalSteps,
    };
  }, [totalSteps, currentStepIndex]);

  const validateStepRoute = () => {
    // we keep track of every step that has been submitted
    const validUntil = Number(SessionStorage.getItem('validUntil'));
    const formValuesFromStorage = SessionStorage.getObject(formId);

    // clubId can be set server-side through URL params, so we check if it is already set. If so, we set valid until step 1.
    if (
      !validUntil &&
      (clubId || formValuesFromStorage?.clubId || formik?.values.clubId)
    ) {
      SessionStorage.setItem('validUntil', '1');
      return;
    }

    // if there is a clubId in the query, we send them to step 2 (with the clubId then already filled in)
    // TODO: Make this redirect more dynamic for all query possibilities
    if (currentStepIndex === 1 && (clubId || formValuesFromStorage?.clubId)) {
      router.push(
        `/funnel/${funnelSlug}/${steps[1].slug}${
          migrated ? '?migrated=true' : ''
        }`,
      );
    }

    // if no steps have been submitted, we send them back to step 1
    if (!validUntil && currentStepIndex !== 0) {
      router.push(`/funnel/${funnelSlug}/${steps[0].slug}`);
    }

    // if some steps have been submitted, but for some reason the user skips one, send them back
    if (validUntil && currentStepIndex > validUntil) {
      router.push(`/funnel/${funnelSlug}/${steps[validUntil].slug}`);
    }
  };

  return {
    goStepNext,
    goStepBack,
    currentStep,
    currentStepIndex,
    totalSteps,
    progress,
    validateStepRoute,
    isClubSelectStep,
    clubSelectStepIndex,
    isStepAfterClubSelect,
    isAccessCodeStep,
  };
}
