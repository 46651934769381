import createImmerReducer from 'utils/redux/helpers/create-immer-reducer'
import * as Actions from './actions'
import { DOMAIN } from './constants'

const initialState = {}

function onFetchContractByPaymentPlanIdSuccess (state, { funnelSlug, contract }) {
  state[funnelSlug] = state[funnelSlug] || {}
  state[funnelSlug][contract.id] = contract
}

function onFetchContractsByClubIdSuccess (state, { funnelSlug, contracts }) {
  state[funnelSlug] = state[funnelSlug] || {}
  // Immer expects us to mutate, so this way we can merge the fetched contracts into the existing state. Alt: return merged state.
  Object.assign(state[funnelSlug], { ...contracts })
}

export default createImmerReducer(DOMAIN, {
  [Actions.fetchContractByPaymentPlanIdSuccess]: onFetchContractByPaymentPlanIdSuccess,
  [Actions.fetchContractsByClubIdSuccess]: onFetchContractsByClubIdSuccess
}, initialState)
