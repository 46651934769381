export function mapLink(item, lang) {
  if (item.link && (item.externalLink || item.link.externalLink)) {
    return {
      ...item,
      link: {
        href: item.externalLink || item.link.externalLink.link,
        openInNewTab:
          item.link.externalLink && item.link.externalLink.openInNewTab,
      },
    };
  }

  // workaround so links to funnel don't end up in catchall [slug]
  if (item.link?.slug && item.link.slug.slice(0, 6) === 'funnel') {
    return {
      ...item,
      link: {
        href: item.link.slug,
        linkAs: item.link.slug,
      },
    };
  }

  if (item.link && Object.keys(item.link).length) {
    return {
      ...item,
      link: {
        slug: item.link.slug,
        href: '/[slug]',
        linkAs: `${item?.link?.slug}`,
      },
    };
  }

  return item;
}

export default function mapItemsWithLink(items, lang = 'nl') {
  return items && items.map((item) => mapLink(item, lang));
}
