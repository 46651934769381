import { getCheckoutClubs } from 'src/services/proxy';
import useSWRImmutable from 'swr/immutable';

export function useClubs(params?: { checkoutConfigId: string }) {
  const { data, error, isLoading } = useSWRImmutable(
    [`/api/checkout/clubs`, params],
    () => getCheckoutClubs(params),
  );

  return {
    clubs: data,
    error,
    isLoading,
  };
}
