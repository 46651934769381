import * as R from 'ramda';
import { Club } from 'src/features/shared/types/Club';
import { IClubWithGeoDistance } from 'src/features/shared/types/IClubWithGeoDistance';

export default function getClosestClubs<
  T extends IClubWithGeoDistance[] | Club.Electrolyte[],
>(
  clubs: T,
  numberOfClubsToShow: number | null = null,
  propName = 'geoDistance',
): T {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const sortedClubs = R.sortWith([
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    R.ascend(R.prop(propName)),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    R.ascend(R.prop('name')),
  ])(clubs);

  return numberOfClubsToShow
    ? sortedClubs.slice(0, numberOfClubsToShow)
    : sortedClubs;
}
