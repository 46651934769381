import isClient from 'utils/is-client';

export function setItem(key, value) {
  if (isLocalStorageAvailable()) {
    localStorage.setItem(key, value);
  }
}

export function getItem(key) {
  if (isLocalStorageAvailable()) {
    return localStorage.getItem(key);
  }
}

export function setObject(key, value) {
  setItem(key, JSON.stringify(value));
}

export function getObject(key) {
  if (isLocalStorageAvailable()) {
    const item = localStorage.getItem(key);
    return item && JSON.parse(item);
  }
}

export function removeItem(key) {
  if (isLocalStorageAvailable()) {
    localStorage.removeItem(key);
  }
}

/**
 * Check if local storage is available
 * for example, safari private mode and Android webviews do not support local storage
 */
export function isLocalStorageAvailable() {
  if (!isClient || typeof localStorage === 'undefined') {
    return false;
  }
  try {
    let test = 'test';
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}
