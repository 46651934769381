import { Company } from '@features/pg-funnel/config/prices'

type DiscountDurations = {
    [key in Company]?: {
        [funnelSlug: string]: {
            [commitmentPeriodId: string]: number
        }
    }
}

export const discountDurations: DiscountDurations = {
  sportcity: {
    student: {
      '12-months-0-days': 12
    }
  }
}
