import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './index.module.scss';

function useLayoutClass(src = '', prefix) {
  return useMemo(
    () =>
      src
        .split(' ')
        .map((col) =>
          prefix
            ? styles[`${prefix}-${col.split(':').join('-')}`]
            : styles[col.split(':').join('-')],
        ),
    [src, prefix],
  );
}

function useRowClass(value, type) {
  return useMemo(() => {
    if (!value) {
      return undefined;
    }
    if (typeof value === 'boolean') {
      return styles[type];
    }
    return value.split(' ').map((screenSize) => {
      return styles[`${type}-${screenSize}`];
    });
  }, [value, type]);
}

/**
 * @deprecated use new Container component in src/features/shared/components/layout
 *
 * Additionally vertical spacing is not handled by the Layout component anymore but the Section component instead
 */
export function Container({ children, className: givenClassName, noPadding }) {
  const className = classNames(styles.container, givenClassName, {
    [styles.noPadding]: noPadding,
  });

  return <div className={className}>{children}</div>;
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  noPadding: PropTypes.bool,
};

/**
 * @deprecated use new Row component in src/features/shared/components/layout
 *
 * Additionally vertical spacing is not handled by the Layout component anymore but the Section component instead
 */
export function Row({
  component: Component,
  
  children,
  className: givenClassName,
  hasNoVerticalReset,
  isVerticallyCentered,
  
  reversed,
  ...props
  
}) {
  const reversedStyle = useRowClass(reversed, 'reversed');
  const className = classNames(styles.row, givenClassName, reversedStyle, {
    [styles['has-no-vertical-reset']]: hasNoVerticalReset,
    [styles['vertically-center']]: isVerticallyCentered,
  });
  return (
    <Component className={className} {...props}>
      {children}
    </Component>
  );
}

Row.propTypes = {
  component: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hasNoVerticalReset: PropTypes.bool,
  reversed: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isVerticallyCentered: PropTypes.bool,
};

Row.defaultProps = {
  component: 'div',
  reversed: false,
};

/**
 * @deprecated use new Col component in src/features/shared/components/layout
 *
 * Additionally vertical spacing is not handled by the Layout component anymore but the Section component instead
 */
export function Col(props) {
  const {
    component: Component,
    children,
    className: givenClassName,
    columns,
    offset,
    hasCenteredContent,
  } = props;

  const columnStyle = useLayoutClass(columns);
  const offsetStyle = offset && useLayoutClass(offset, 'offset');
  const className = classNames([
    styles.column,
    givenClassName,
    columnStyle,
    offsetStyle,
    {
      [styles.centered]: hasCenteredContent,
    },
  ]);

  return <Component className={className}>{children}</Component>;
}

Col.propTypes = {
  component: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  columns: PropTypes.string,
  offset: PropTypes.string,
  hasCenteredContent: PropTypes.bool,
};

Col.defaultProps = {
  component: 'div',
};

/**
 * @deprecated use new Layout component from src/features/shared/components/layout
 */
export default {
  Container,
  Row,
  Col,
};
