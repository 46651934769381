import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FunnelConfig
} from '@features/pg-funnel/hooks/use-funnel-config/types'
import {
  EnrichedPaymentPlan
} from '@features/pg-funnel/services/perfect-gym/mappers/map-payment-plans'
import { ClubsById } from '@features/pg-funnel/utils/funnel'
import {
  ContractsByPaymentPlanId, EnrichedPaymentPlansById
} from '@features/pg-funnel/types'
import { ContractWithPaymentPlanId } from '@features/pg-funnel/services/perfect-gym/types'
import { Voucher } from '@features/pg-funnel/services/perfect-gym/mappers/map-voucher'

export interface FunnelState {
    clubsById: {
      [funnelSlug: string]: ClubsById
    }
    paymentPlansById: {
      [funnelSlug: string]: EnrichedPaymentPlansById
    }
    funnelConfig: {
      [funnelSlug: string]: FunnelConfig }
    selectedPaymentPlan: {
      [funnelSlug: string]: EnrichedPaymentPlan | null
    }
    contractsByPaymentPlanId: {
      [funnelSlug: string]: {
      error: {
        statusCode: number
        message: string
      }
      contracts: ContractsByPaymentPlanId
      }
    }
    filteredPaymentPlans: {
      [funnelSlug: string]: EnrichedPaymentPlan[]
    }
    activeVoucher: {
      [funnelSlug: string]: Voucher | null
    }
}

const initialState: FunnelState = {
  clubsById: {},
  paymentPlansById: {},
  funnelConfig: {},
  selectedPaymentPlan: {},
  contractsByPaymentPlanId: {},
  filteredPaymentPlans: {},
  activeVoucher: {}
}

const slice = createSlice({
  name: 'funnel',
  initialState,
  reducers: {
    setFunnelConfig: (state, action: PayloadAction<{funnelSlug: string, funnelConfig: FunnelConfig}>) => {
      state.funnelConfig[action.payload.funnelSlug] = action.payload.funnelConfig
    },
    setClubsById: (state, action: PayloadAction<{ funnelSlug: string, clubsById: ClubsById }>) => {
      state.clubsById[action.payload.funnelSlug] = action.payload.clubsById
    },
    setPaymentPlansById: (state, action: PayloadAction<{funnelSlug: string, paymentPlansById: EnrichedPaymentPlansById}>) => {
      state.paymentPlansById[action.payload.funnelSlug] = action.payload.paymentPlansById
    },
    setSelectedPaymentPlan: (state, action: PayloadAction<{ funnelSlug: string, selectedPaymentPlan: EnrichedPaymentPlan | null }>) => {
      state.selectedPaymentPlan[action.payload.funnelSlug] = action.payload.selectedPaymentPlan
    },
    setFilteredPaymentPlans: (state, action: PayloadAction<{ funnelSlug: string, paymentPlans: EnrichedPaymentPlan[]}>) => {
      state.filteredPaymentPlans[action.payload.funnelSlug] = action.payload.paymentPlans
    },
    setContractsByPaymentPlanId: (state, action: PayloadAction<{ funnelSlug: string, contracts: ContractsByPaymentPlanId }>) => {
      state.contractsByPaymentPlanId[action.payload.funnelSlug].contracts = action.payload.contracts
    },
    setActiveVoucher: (state, action: PayloadAction<{ funnelSlug: string, voucher: Voucher | null }>) => {
      state.activeVoucher[action.payload.funnelSlug] = action.payload.voucher
    },
    fetchContractFailed: (state, action: PayloadAction<{funnelSlug: string, error: { statusCode: number, message: string }}>) => {
      state.contractsByPaymentPlanId[action.payload.funnelSlug].error = action.payload.error
    },
    fetchContractSucceeded: (state, action: PayloadAction<{funnelSlug: string, contract: ContractWithPaymentPlanId}>) => {
      state.contractsByPaymentPlanId[action.payload.funnelSlug].contracts[action.payload.contract.id] = action.payload.contract
    },
    fetchContract: (state, action: PayloadAction<{
      funnelSlug: string
      clubId: string
      paymentPlanId: string
      discountIds: string[]
    }
        >) => {
      console.log(state, action)
    }
  }
})

const funnelSlice = {
  initialState,
  ...slice
}

export default funnelSlice
