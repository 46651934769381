import { useQuery } from 'react-query'
import { specialistsKeys } from '../../shared/react-query-keys/specialists'
import { STALE_TIME } from '../../shared/constants/staleTime'
import { getSpecialistsData } from '../services/proxy/specialists'
import { ISpecialist } from '../types/ISpecialist'

interface IUseSpecialistsReturn {
  specialists?: ISpecialist[]
}

export const useSpecialists = (clubId: string | number) : IUseSpecialistsReturn => {
  const { data: specialists } = useQuery<ISpecialist[], Error>(
    specialistsKeys.list(clubId.toString()),
    async () => {
      const res = await getSpecialistsData({ clubId })
      return res.data
    },
    {
      staleTime: STALE_TIME,
      cacheTime: STALE_TIME * 1.5
    }
  )

  return { specialists }
}
