import styles from './index.module.scss';
import Icons from 'features/shared/components/icons';
import { useIntl } from '@domains/i18n';
import { ListAlphabetical } from '@features/pg-funnel/components/content-items/funnel-usp-block/list-alphabetical';
import { useClubDataFromConfig } from '@features/pg-funnel/hooks/use-club-config';
import { useFunnel } from '@features/pg-funnel/hooks/use-funnel';
import { groupAlphabetically } from '@features/pg-funnel/utils/group-alphabetically';
import { GA4_EVENTS, pushGA4Event } from '@features/shared/utils/ga4';
import classNames from 'classnames';
import { useSheet } from '@features/shared/hooks/use-sheet';
import { Sheet } from '@features/shared/components/sheet';
import { Body, FontColor } from 'src/features/shared/components/typography';

interface Props {
  clubId: number;
  isSecondary?: true;
  isTertiary?: true;
  isColorNeutral02?: boolean;
}

export const ClubsListUsp = ({
  clubId,
  isSecondary,
  isTertiary,
  isColorNeutral02,
}: Props) => {
  const { formatMessage } = useIntl();
  const sheet = useSheet();
  const { clubsById } = useFunnel();
  const { availableClubs } = useClubDataFromConfig(clubId, clubsById);

  const clubNames = availableClubs
    .map((club) => club?.cms?.title)
    .filter(Boolean)
    .sort((a, b) => a.localeCompare(b));
  const clubNamesByAlphabet = groupAlphabetically(clubNames);

  const onClick = () => {
    pushGA4Event(GA4_EVENTS.trackEvent, { event_name: 'click_clubinfo' });
    sheet.toggle();
  };

  return (
    <>
      <Sheet
        sheet={sheet}
        title={formatMessage('funnel.club-select.clubs-amount-tooltip-title')}
        subtitle={formatMessage(
          'funnel.club-select.clubs-amount-tooltip-description',
          { numberOfClubs: availableClubs.length },
        )}
      >
        <ListAlphabetical itemsByAlphabet={clubNamesByAlphabet} />
      </Sheet>
      <Body color={isColorNeutral02 && FontColor.Neutral02}>
        {isSecondary ? <Icons.Checkmark className={styles.icon} /> : null}
        {isTertiary ? (
          <Icons.Checkmark className={classNames(styles.icon)} />
        ) : null}
        {isTertiary ? (
          <span className={classNames(styles.tooltip, styles.usp)}>
            {formatMessage('funnel.club-select.clubs-amount-usp-1')}
            <button
              className={styles.underline}
              onClick={onClick}
              aria-label="info"
              type="button"
            >
              {availableClubs.length} clubs
            </button>
            {formatMessage('funnel.club-select.clubs-amount-usp-2')}
          </span>
        ) : (
          <span
            className={classNames(styles.tooltip, {
              [styles.usp]: isSecondary || isTertiary,
              [styles.block]: isTertiary,
            })}
          >
            {formatMessage('funnel.club-select.clubs-amount-usp', {
              numberOfClubs: availableClubs.length,
            })}
            <button
              className={styles.button}
              onClick={onClick}
              aria-label="info"
              type="button"
              data-testid="clubs-list-usp-button"
            >
              <Icons.InfoCircleFilled className={styles.infoIcon} />
            </button>
          </span>
        )}
      </Body>
    </>
  );
};
