export const breakClubTitleName = (name: string) => {
  switch (name) {
    case 'Amsterdam Lijnbaansgracht':
      return 'Amsterdam Lijnbaans&shy;gracht'
    case 'Dordrecht Sportboulevard':
      return 'Dordrecht Sport&shy;boulevard'
    case 'Amsterdam Valkenburgerstraat':
      return 'Amsterdam Valken&shy;burger&shy;straat'
    case 'Amsterdam Buikslotermeerplein':
      return 'Amsterdam Buiksloter&shy;meerplein'
    case 'Amsterdam Weteringdwarsstraat':
      return 'Amsterdam Wetering&shy;dwars&shy;straat'
    case 'Den Haag Leidschenveen':
      return 'Den Haag Leidschen&shy;veen'
    case 'Den Haag Loosduinseweg':
      return 'Den Haag Loosduinse&shy;weg'
    case 'Eindhoven Mecklenburgstraat':
      return 'Eindhoven Mecklenburg&shy;straat'
    case 'Leiden Lammenschans':
      return 'Leiden Lammen&shy;schans'
    case 'Rotterdam Zomerhofstraat':
      return 'Rotterdam Zomerhof&shy;straat'
    default:
      return name
  }
}
