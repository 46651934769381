import classNames from 'classnames';
import Icons from 'features/shared/components/icons';
import styles from './index.module.scss';
import { ComponentProps } from 'react';

export enum ButtonPosition {
  Default = 'default',
  AbsoluteTop = 'absolute-top',
  AbsoluteBottom = 'absolute-bottom',
  Centered = 'centered',
}

interface Props extends ComponentProps<'button'> {
  isPrev?: boolean;
  isAlternative?: boolean;
  place?: ButtonPosition;
  isHidden?: boolean;
}

export function ArrowButton({
  isPrev,
  isAlternative,
  place = ButtonPosition.Default,
  isHidden = false,
  className,
  ...props
}: Props) {
  const Arrow = isPrev ? Icons.ArrowLeft : Icons.ArrowRight;

  const classes = className?.split(' ');
  const isDisabled = classes && classes.includes('slick-disabled');
  const buttonClassName = classNames(
    styles.button,
    {
      [styles.prev]: isPrev,
      [styles.alternative]: isAlternative,
      [styles.absoluteTop]: place === ButtonPosition.AbsoluteTop,
      [styles.absoluteBottom]: place === ButtonPosition.AbsoluteBottom,
      [styles.centered]: place === ButtonPosition.Centered,
      [styles.hidden]: isHidden,
    },
    className,
  );

  return (
    <button
      type="button"
      className={buttonClassName}
      disabled={isDisabled}
      {...props}
    >
      <Arrow className={styles.icon} />
    </button>
  );
}
