import { createSelector } from 'reselect'
import reducer from './reducers'

export const getState = state => state[reducer] || {}

export const getContractByPaymentPlanId = (funnelSlug, paymentPlanId) => createSelector(
  getState,
  (state) => state[funnelSlug]?.[paymentPlanId]
)

export const getContractsByFunnelSlug = (funnelSlug) => createSelector(
  getState,
  (state) => state[funnelSlug]
)
