import { createResolvableActionsDomain } from '@touchtribe/redux-helpers'
import { DOMAIN } from './constants'

const createResolvableActions = createResolvableActionsDomain(DOMAIN)

export const [
  fetchContractsByClubId,
  fetchContractsByClubIdSuccess,
  fetchContractsByClubIdFail
] = createResolvableActions('fetchContractsByClubId', {
  init: (funnelSlug, clubId, paymentPlansById) => ({ funnelSlug, clubId, paymentPlansById }),
  resolve: (funnelSlug, contracts) => ({ funnelSlug, contracts }),
  reject: (statusCode, message) => ({ statusCode, message })
})

export const [
  fetchContractByPaymentPlanId,
  fetchContractByPaymentPlanIdSuccess,
  fetchContractByPaymentPlanIdFail
] = createResolvableActions('fetchContractByPaymentPlanId', {
  init: (funnelSlug, clubId, paymentPlan) => ({
    funnelSlug, clubId, paymentPlan
  }),
  resolve: (funnelSlug, contract) => ({ funnelSlug, contract }),
  reject: (statusCode, message) => ({ statusCode, message })
})
