import { useEffect, useState } from 'react';

export enum GeolocationError {
  PERMISSION_DENIED = 1,
  POSITION_UNAVAILABLE = 2,
  TIMEOUT = 3,
}

export interface GeoLocationSensorState {
  isLoading: boolean;
  accuracy: number | null;
  altitude: number | null;
  altitudeAccuracy: number | null;
  heading: number | null;
  latitude: number | null;
  longitude: number | null;
  speed: number | null;
  timestamp: number | null;
  error?: GeolocationPositionError;
}

export const useGeolocation = (
  options?: PositionOptions,
  isEnabled: boolean = true,
): GeoLocationSensorState => {
  const [state, setState] = useState<GeoLocationSensorState>({
    isLoading: false,
    accuracy: null,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    latitude: null,
    longitude: null,
    speed: null,
    timestamp: Date.now(),
  });

  useEffect(() => {
    if (!isEnabled) return; // Exit early if geolocation is not enabled

    let mounted = true;
    let watchId: number | undefined = undefined;

    const onEvent: PositionCallback = (event) => {
      if (mounted) {
        setState({
          isLoading: false,
          accuracy: event.coords.accuracy,
          altitude: event.coords.altitude,
          altitudeAccuracy: event.coords.altitudeAccuracy,
          heading: event.coords.heading,
          latitude: event.coords.latitude,
          longitude: event.coords.longitude,
          speed: event.coords.speed,
          timestamp: event.timestamp,
        });
      }
    };

    const onEventError: PositionErrorCallback = (error) =>
      mounted &&
      setState((oldState) => ({ ...oldState, isLoading: false, error }));

    navigator.geolocation.getCurrentPosition(onEvent, onEventError, options);
    watchId = navigator.geolocation.watchPosition(
      onEvent,
      onEventError,
      options,
    );

    return () => {
      mounted = false;
      if (watchId !== undefined) {
        navigator.geolocation.clearWatch(watchId);
      }
    };
  }, [isEnabled]);

  return state;
};
