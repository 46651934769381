import { EnrichedPaymentPlan } from '@features/pg-funnel/services/perfect-gym/mappers/map-payment-plans';
import { MembershipPrices } from '@features/pg-funnel/hooks/use-membership-prices';
import { Voucher } from '@features/pg-funnel/services/perfect-gym/mappers/map-voucher';
import isServer from '@utils/is-server';
import theme from '@config/theme';
import { AnalyticsMeasurement } from '@features/pg-funnel/types/analytics';
import { FormValues } from '../../types';
import { getAnalyticsClientId } from './getAnalyticsClientId';
import { getAnalyticsSessionId } from './getAnalyticsSessionId';
import { ClubWithCmsData } from '../funnel';
import { getClubAddonVariantByPaymentPlanId } from '../getClubAddonVariantByPaymentPlanId';

type Params = {
  selectedPaymentPlan: EnrichedPaymentPlan;
  prices: MembershipPrices;
  voucher?: Voucher | null;
  formValues: FormValues;
  club?: ClubWithCmsData;
  funnelSlug: string;
};

const MAP_TERM_TO_TEXT: { [key: string]: string } = {
  1: 'flex',
  28: '4-weeks',
  6: '6-months',
  12: '1-year',
};

export interface AnalyticsQueries {
  ua: string; // a (ua) measurement protocol url
  ga: AnalyticsMeasurement; // a measurement json which can be posted to a GA4 endpoint
}

export const getAnalyticsQueries = ({
  selectedPaymentPlan,
  voucher,
  prices,
  formValues,
  club,
  funnelSlug,
}: Params): AnalyticsQueries | undefined => {
  if (isServer) return;

  const analyticsClientId = getAnalyticsClientId();
  const analyticsSessionId = getAnalyticsSessionId();

  const urlParams = new URLSearchParams({
    tid: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID as string,
    cid: analyticsClientId ?? '',
    tr: prices.membershipFee?.toFixed(2) as string,
    ec: 'Ecommerce',
    ea: 'Purchase',
    cu: 'EUR',
    pa: 'purchase',
    el: selectedPaymentPlan.name,
    dl: `${theme.name}/funnel/betaling-gelukt`,
  }).toString();

  const universalAnalyticsQuery = `https://www.google-analytics.com/collect?v=1&t=event&${urlParams}`;

  const duration =
    MAP_TERM_TO_TEXT[
      selectedPaymentPlan.commitmentPeriod.days ||
        selectedPaymentPlan.commitmentPeriod.months
    ];
  const paymentPlan =
    MAP_TERM_TO_TEXT[
      selectedPaymentPlan.paymentInterval.days ||
        selectedPaymentPlan.paymentInterval.months
    ];

  const measurement: AnalyticsMeasurement = {
    client_id: analyticsClientId,
    events: [
      {
        name: 'purchase',
        params: {
          affiliation: theme.brand,
          currency: 'EUR',
          engagement_time_msec: 1,
          session_id: analyticsSessionId,
          transaction_id: '',
          value: (prices.discountedPricePerMonth ||
            prices.pricePerMonth) as number,
          revenue: prices.membershipFee,
          items: [
            {
              item_variant: 'membership',
              item_id: selectedPaymentPlan.id.toString(),
              item_name: selectedPaymentPlan.name,
              item_brand: funnelSlug,
              item_category: formValues.contractType,
              item_duration: duration,
              item_paymentplan: paymentPlan,
              item_homeclub: club?.name,
              price: prices.pricePerMonth,
              voucher: voucher?.summary.voucher.description,
              voucher_code: voucher?.summary.voucher.code,
              voucher_discount: voucher?.summary.voucher.amount,
            },
          ],
        },
      },
    ],
  };

  if (club) {
    formValues.addons?.forEach((addon) => {
      const addOnVariant = getClubAddonVariantByPaymentPlanId(
        club,
        addon.paymentPlanId,
      );

      if (!addOnVariant) {
        return;
      }

      measurement.events[0].params.items.push({
        item_id: addon.paymentPlanId.toString(),
        item_name: addOnVariant.name,
        item_variant: 'add-on',
        item_brand: 'yanga',
        item_homeclub: club?.name,
        price: addOnVariant.price,
        promotion_id: addon.contractDiscountDefinitionId,
      });
    });
  }

  return {
    ua: universalAnalyticsQuery,
    ga: measurement,
  };
};
