import styles from './UseMyLocationButton.module.scss';
import Icons from 'features/shared/components/icons';
import { Body } from 'src/features/shared/components/typography';
import { Button } from 'src/features/shared/components/button';
import { useIntl } from 'src/domains/i18n';
import { GeolocationError } from 'src/features/shared/hooks/use-geo-location';
import classNames from 'classnames';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';
import { ComponentProps } from 'react';
import { Loading } from '../loading';

interface IUseMyLocationButtonProps
  extends Omit<ComponentProps<typeof Button>, 'icon' | 'children'> {
  isLoading?: boolean;
  isLight?: boolean;
  error?: GeolocationPositionError;
  children?: React.ReactNode;
}

export function UseMyLocationButton({
  isLoading,
  error,
  isLight,
  className,
  children,
  ...buttonProps
}: IUseMyLocationButtonProps) {
  const { formatMessage } = useIntl();
  const messageClassName = classNames({ [styles.light]: isLight }, className);

  if (error) {
    let message;

    switch (error.code) {
      case GeolocationError.PERMISSION_DENIED:
        message = formatMessage(
          'use-my-location-button.error.permission-denied',
        );
        break;
      case GeolocationError.POSITION_UNAVAILABLE:
        message = formatMessage('use-my-location-button.error.unavailable');
        break;
      case GeolocationError.TIMEOUT:
      default:
        message = formatMessage('use-my-location-button.error.default');
    }

    return <Body className={messageClassName}>{message}</Body>;
  }

  return (
    <Button
      icon={
        isLoading ? <Loading className={styles.loading} /> : <Icons.Location />
      }
      variant={ButtonVariant.Link}
      iconPosition="end"
      aria-busy={isLoading}
      className={classNames(
        styles.button,
        { [styles.light]: isLight },
        className,
      )}
      {...buttonProps}
    >
      {children ?? formatMessage('use-my-location-button.label')}
    </Button>
  );
}
