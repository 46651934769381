import { ISpecialist } from '../types/ISpecialist'
import { ISpecialists } from '../types/ISpecialists'
import { mapSpecialistData } from './map-specialist-data'

export const mapSpecialistsData = (specialistsData: ISpecialists) => {
  const specialists: ISpecialist[] = []

  Object.keys(specialistsData).forEach((key) => {
    const specialist = specialistsData[key]
    specialists.push(mapSpecialistData(specialist))
  })

  return specialists as ISpecialist[]
}
