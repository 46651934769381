// Get google analytics sessionID from cookie
// Cookie value example: 'GS1.1.1659710029.4.1.1659710504.0'.
// Session Id:                  ^^^^^^^^^^.
export function getAnalyticsSessionId() {
  const cookieName = '_ga_'
  const sessionId = document.cookie
    .split('; ')
    .find((row) => row.startsWith(cookieName))
    ?.split('=')[1]
    .split('.')[2]

  return sessionId
}
