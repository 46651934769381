export function convertSpacesToDashCase(str: string): string {
  return str.toLocaleLowerCase().replace(/(\s)/g, '-');
}

/**
 * Transforms slug into a title name
 * e.g.
 * "den-haag" -> "Den Haag"
 * "utrecht" -> "Utrecht"
 */
export function getTitleFromSlug(slug: string) {
  return slug.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
}
