import { ClubWithCmsData } from './funnel';

export function getClubAddonVariantByPaymentPlanId(
  club: ClubWithCmsData,
  paymentPlanId: number,
) {
  for (const addOn of club.addOns) {
    const variant = addOn.variants.find(
      (variant) => variant.paymentPlanId === paymentPlanId,
    );
    if (variant) {
      return variant;
    }
  }
}
