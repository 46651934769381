import styles from './index.module.scss';
import { Image } from '../image';
import { ComponentProps, useEffect, useRef } from 'react';

export interface ICarouselImageProps extends ComponentProps<typeof Image> {
  onUpdateHeight?: (height: number) => void;
  isAlternative?: boolean;
}

export function CarouselImage({
  image,
  onUpdateHeight,
  isAlternative,
  ...props
}: ICarouselImageProps) {
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const updateImageHeight = () => {
      if (imageRef?.current?.offsetHeight != null) {
        onUpdateHeight?.(imageRef?.current?.offsetHeight);
      }
    };

    const onResize = () => updateImageHeight();
    const onLoad = () => updateImageHeight();

    if (onUpdateHeight) {
      window.addEventListener('resize', onResize);
      imageRef.current?.addEventListener('load', onLoad);
    }

    return () => {
      window.removeEventListener('resize', onResize);
      imageRef.current?.removeEventListener('load', onLoad);
    };
  }, [imageRef.current, onUpdateHeight]);

  return (
    <Image
      ref={imageRef}
      hasLazyLoading={!isAlternative}
      className={styles.image}
      image={image}
      {...props}
    />
  );
}
