import theme from '@config/theme';
import { discountDurations } from '@features/pg-funnel/config/discount-durations';
import Prices, { Company } from '@features/pg-funnel/config/prices';
import { SixMonthOneOffContractIds } from '@features/pg-funnel/config/six-month-one-off-contract-ids';
import { useContractByPaymentPlanId } from '@features/pg-funnel/domains/contracts/hooks';
import { EnrichedPaymentPlan } from '@features/pg-funnel/services/perfect-gym/mappers/map-payment-plans';
import { Voucher } from '@features/pg-funnel/services/perfect-gym/mappers/map-voucher';
import {
  ChargeType,
  ContractWithPaymentPlanId,
} from '@features/pg-funnel/services/perfect-gym/types';
import { activeVoucherSelector } from '@features/pg-funnel/store/selectors';
import { useParams } from 'next/navigation';
import { useSelector } from 'react-redux';
import {
  isAcceptance,
  isProduction,
} from 'src/features/shared/utils/is-production';

export interface MembershipPrices {
  pricePerMonth?: number;
  discountedPricePerMonth?: number;
  priceBeforeDiscountPerMonth?: number;
  discountPercentPerMonth?: number;
  membershipFee?: number;
  membershipFeeBeforeDiscount?: number;
  joiningFee?: number;
  joiningFeeDiscount?: number;
  defaultJoiningFee?: number;
  defaultMembershipFee?: number;
  discountTotal?: number;
  discountDuration?: number;
  followUpPrice?: number;
  discountPerMonth?: number;
  hasVoucher?: boolean;
}

/*
  Takes prices from paymentPlan, contract & voucher data, and enriches them with hardcoded discount durations to return the membership prices we show in the summary.
 */
export const getMembershipPrices = (
  slug: string,
  paymentPlan: EnrichedPaymentPlan,
  contract: ContractWithPaymentPlanId,
  voucher?: Voucher | null,
): MembershipPrices => {
  /*
    paymentPlan.membershipFee gives you the total for one-off payments (e.g. 782.01)
    but the regular monthly price for other contracts (e.g. 59.99)
    so to get the total defaultMembershipFee we multiply charges * membershipFee
   */
  // workaround for an issue with the 6-month and 6-months period contracts, which return 2 membership charges instead of 1
  const environment = isProduction() || isAcceptance() ? 'production' : 'test';
  const membershipCharges = SixMonthOneOffContractIds[environment].includes(
    contract.id,
  )
    ? [
        contract.charges.filter(
          (charge) => charge.type === ChargeType.MEMBERSHIP,
        )[0],
      ]
    : contract.charges.filter(
        (charge) => charge.type === ChargeType.MEMBERSHIP,
      );
  const defaultMembershipFee =
    membershipCharges.length * paymentPlan.membershipFee;
  // To get the actual price, we just add up all the charges
  const membershipFee = membershipCharges?.reduce(
    (acc, { amount }) => acc + amount,
    0,
  );
  const priceBeforeDiscountPerMonth =
    membershipCharges.length > 1 &&
    membershipCharges[0].amount <= paymentPlan.membershipFee
      ? membershipCharges[0].amount
      : undefined;
  /*
    If a voucher is activated, we apply the discount received from Electrolyte
   */
  if (voucher) {
    const membershipCharges = voucher.charges.filter(
      (charge) => charge.type === ChargeType.MEMBERSHIP,
    );
    const discountedPricePerMonth =
      membershipCharges[0].amount < paymentPlan.membershipFee
        ? membershipCharges[0].amount
        : undefined;
    const joiningFeeDiscount =
      voucher.summary.joiningFee.regularAmount !==
      voucher.summary.joiningFee.amount
        ? voucher.summary.joiningFee.amount -
          voucher.summary.joiningFee.regularAmount
        : undefined;
    const discountDuration =
      discountDurations?.[theme.name as Company]?.[slug as string]?.[
        paymentPlan.attributes.commitmentPeriodId
      ] ||
      membershipCharges?.filter(
        (charge) => charge.amount < paymentPlan.membershipFee,
      ).length;
    const discountPerMonth = discountedPricePerMonth
      ? discountedPricePerMonth - paymentPlan.membershipFee
      : undefined;

    return {
      pricePerMonth: paymentPlan.membershipFee, // since we only use this var in month-contracts we can directly pass the membershipFee
      joiningFee: voucher.summary.joiningFee.amount,
      defaultJoiningFee: voucher.summary.joiningFee.regularAmount,
      joiningFeeDiscount,
      membershipFeeBeforeDiscount: membershipFee,
      membershipFee: voucher.summary.membership.total,
      defaultMembershipFee: voucher.summary.membership.regularAmount,
      priceBeforeDiscountPerMonth,
      discountedPricePerMonth,
      discountPerMonth,
      discountTotal: -voucher.summary.voucher.amount,
      discountDuration,
      hasVoucher: true,
    };
  }
  /*
   Assuming discounts are applied at the start, we check if there's a difference
   between the first month and regular price and return the first month's price if there is.
   */
  const discountTotal =
    defaultMembershipFee &&
    membershipFee &&
    membershipFee - defaultMembershipFee;
  const discountDuration =
    discountDurations?.[theme.name as Company]?.[slug as string]?.[
      paymentPlan.attributes.commitmentPeriodId
    ] ||
    membershipCharges?.filter(
      (charge) => charge.amount < paymentPlan.membershipFee,
    ).length;

  const defaultJoiningFee = paymentPlan.joiningFee;
  const joiningFee = contract?.charges.find(
    (charge) => charge.type === ChargeType.JOINING_FEE,
  )?.amount;
  const joiningFeeDiscount =
    defaultJoiningFee && joiningFee !== undefined
      ? joiningFee - defaultJoiningFee
      : undefined;
  return {
    pricePerMonth: paymentPlan.membershipFee, // since we only use this var in month-contracts we can directly pass the membershipFee
    joiningFee, // actual joining fee paid by member, e.g. 0,-
    joiningFeeDiscount, // total discount applied to joining fee, e.g. -39.99
    membershipFee, // total price for membership (without joining fee or other one-off costs)
    discountedPricePerMonth: priceBeforeDiscountPerMonth, // price of the first month
    defaultJoiningFee,
    defaultMembershipFee, // default total price for membership charges
    discountTotal, // total discount applied for the membership costs
    discountDuration, // number of months a discount is applied
    hasVoucher: false,
  };
};

export const useMembershipPrices = (
  paymentPlan: EnrichedPaymentPlan | null,
  clubId: number | null,
): MembershipPrices => {
  const { slug } = useParams<{ slug: string }>();
  const [contract] = useContractByPaymentPlanId(
    slug,
    clubId,
    paymentPlan as EnrichedPaymentPlan | undefined,
  ) as unknown as [contract: ContractWithPaymentPlanId]; // todo: fix types / inference
  const activeVoucher = useSelector(activeVoucherSelector(slug as string));

  if (paymentPlan) {
    /*
      Hardcoded prices
     */
    if (theme.funnel?.useHardcodedPrices?.[slug as string]) {
      const perfectGymUrl = process.env.NEXT_PUBLIC_PG_URL;
      const isStaging = perfectGymUrl?.includes('test');
      const themeName: string = theme.name;
      const prices = isStaging
        ? Prices.staging[themeName as Company]
        : Prices.production[themeName as Company];

      return {
        ...prices[slug as string][paymentPlan.id],
      };
    }

    /*
      Prices based on contract (and voucher)
     */
    if (contract) {
      return getMembershipPrices(
        slug as string,
        paymentPlan,
        contract,
        activeVoucher,
      );
    }
  }

  return {};
};
