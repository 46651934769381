import isClient from '@utils/is-client';

export function setItem(key: string, value: string) {
  if (isClient) {
    sessionStorage.setItem(key, value);
  }
}

export function getItem(key: string) {
  if (isClient) {
    return sessionStorage.getItem(key);
  }
}

export function setObject(key: string, value: object) {
  setItem(key, JSON.stringify(value));
}

export function getObject(key: string) {
  if (isClient) {
    const item = sessionStorage.getItem(key);
    return item && JSON.parse(item);
  }
}

export function removeItem(key: string) {
  if (isClient) {
    sessionStorage.removeItem(key);
  }
}
