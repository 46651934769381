/* eslint-disable camelcase */

export interface Club {
    openingDate: Date
    isHidden: boolean
    name: string
    shortName: string
    email: string
    phoneNumber: string
    longitude: number
    latitude: number
    countryId: number
    cityId: number
    address: string
    postalCode: string
    photoId: number
    photoUrl: string
    timezoneId: string
    membersLimitInClub: number
    symbol: string
    isDeleted: boolean
    version: number
    id: number
    country: {
        symbol: string
        name: string
        nativeName: string
        phonePrefix: string
        id: number
    }
    city: {
        name: string
        countryId: number
        id: number
    }
}

export interface PaymentPlan {
    isActive: boolean,
    name: string,
    displayName: string,
    descriptionShort: string,
    descriptionLong: string,
    isAdditional: boolean,
    isDeleted: boolean,
    version: number,
    id: number,
    joiningFee: {
        net: number,
        gross: number
    },
    adminFee: {
        net: number,
        gross: number
    },
    membershipFee: {
        net: number,
        gross: number
    },
    paymentInterval: {
        days: number,
        months: number
    },
    commitmentPeriod: {
        days: number,
        months: number
    }
    availableInClubs: Club[]
}

export enum CommitmentPeriod {
    DAY_PASS ='0-months-2-days',
    FOUR_WEEK ='0-months-28-days',
    MONTH ='1-months-0-days',
    TWELVE_WEEK ='0-months-84-days',
    SIX_MONTHS = '6-months-0-days',
    TWELVE_MONTHS = '12-months-0-days',
    YEAR = '0-months-364-days',
    TWO_YEAR = '0-months-728-days',
}

export enum PaymentInterval {
    DAY_PASS ='0-months-2-days',
    FOUR_WEEKS ='0-months-28-days',
    TWELVE_WEEK ='0-months-84-days',
    SIX_MONTHS = '6-months-0-days',
    TWELVE_MONTHS = '12-months-0-days'
}

export interface Discount {
    isActive: boolean,
    name: string,
    isAvailableInAllClubs: boolean,
    isAvailableForAllPaymentPlans: boolean,
    canBeCombinedWithAllDiscountDefinitions: boolean,
    membershipFeeDiscountType: string,
    id: number,
    parameters: never[], // todo: define
    clubs: Club[],
    paymentPlans: PaymentPlan[]
}

export interface DiscountDefinition {
    contractDiscountDefinitionId: number,
    parameters?: {
        sinceInterval: number,
        untilInterval: number
    }
}

export interface SignUpData {
    funnel: string,
    homeClubId: number,
    personalData: {
        firstName: string,
        lastName: string,
        birthDate: string,
        phoneNumber: string,
        email: string,
        sex: string
    },
    addressData: {
        street: string,
        additionalAddressLine: string,
        postalCode: string,
        cityName: string,
        countrySymbol: string
    },
    contractData: {
        paymentPlanId: number,
        contractDiscountsData?: DiscountDefinition[],
        signUpDate: Date,
        startDate: Date | string
        voucherCode?: string
    },
    addons?: {
        paymentPlanId: number;
        contractDiscountDefinitionId?: number;
    }[],
    studentNumber?: string
    skipPaymentValidation?: boolean
}

export enum ChargeType {
    PRO_RATA = 'Prorata',
    JOINING_FEE = 'JoiningFee',
    MEMBERSHIP = 'Membership'
}

export type Charge = {
    amount: number
    chargeDate: string
    description: string
    dueDate: string
    type: ChargeType
}

export interface Contract {
    // all date strings are as follows: "2022-07-08T11:49:50.165Z"
    charges: Charge[]
    commitmentDate: string
    endDate: string
    startDate: string
}

export interface ContractWithPaymentPlanId extends Contract {
    id: number
}

export type VoucherData = Contract & {
    summary: {
        voucher: {
            code: string,
            valid: boolean,
            description: string
            amount: number
            discountId: number
            sinceInterval: number
            untilInterval: number
        },
        joining_fee: {
            amount: number
            without_voucher: number
            regular_amount: number
        },
        membership: {
            total: number
            without_voucher: number
            regular_amount: number
        }
    }
}

