export function removeDuplicateObjects<T> (arr: T[], key: keyof T) {
  const seenItems = new Set()
  return arr.filter((obj) => {
    const keyValue = key ? obj[key] : JSON.stringify(obj)
    if (!seenItems.has(keyValue)) {
      seenItems.add(keyValue)
      return true
    }
    return false
  })
}
