import {
  clubsByIdSelector,
  funnelConfigBySlugSelector,
} from '@features/pg-funnel/store/selectors';
import * as SessionStorage from '@features/pg-funnel/utils/session-storage';
import { useParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Club } from '../../services/proxy/types/IClubs';
import { FUNNEL_STEP_SLUGS } from '../../utils/constants';
import { getFunnelFormId } from '../../utils/getFunnelFormId';
import { ContentTypes, FunnelConfig } from './types';

interface UseFunnelConfigResult {
  config: FunnelConfig;
}

export default function useFunnelConfig(): UseFunnelConfigResult {
  const query = useParams();
  const step = query.step;
  const funnelConfig = useSelector(
    funnelConfigBySlugSelector(query.slug as string),
  );
  const clubsById = useSelector(clubsByIdSelector(query.slug as string));
  const formData =
    typeof query.slug === 'string'
      ? SessionStorage.getObject(getFunnelFormId(query.slug))
      : null;
  const [selectedClub, setSelectedClub] = useState<Club | undefined>();

  useEffect(() => {
    if (formData?.clubId) {
      setSelectedClub(clubsById[formData.clubId]);
    }
  }, [formData?.clubId, clubsById]);

  /**
   * Add-ons step should be skipped when there are none for the selected club
   * If there is no selected club we want to return the step anyway
   */
  const filteredSteps = funnelConfig?.steps
    ? step !== FUNNEL_STEP_SLUGS.addOns &&
      (!selectedClub || selectedClub.addOns.length === 0)
      ? funnelConfig.steps.filter(
          (step) =>
            !step.contentItems?.find(
              (item) => item.type === ContentTypes.FUNNEL_ADD_ONS_SECTION,
            ),
        )
      : funnelConfig.steps
    : [];

  return {
    config: { ...funnelConfig, steps: filteredSteps },
  };
}
