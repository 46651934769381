import { CLUB_ATTRIBUTE_ICON_MAP } from '@features/club-page/constants/club-attribute-icon-map';
import styles from './index.module.scss';
import classNames from 'classnames';
import { Body } from 'src/features/shared/components/typography';

interface IAttributeProps {
  title: string;
  icon?: string;
  hasGreyBackground?: boolean;
}

export const Attribute = ({
  title,
  icon,
  hasGreyBackground,
}: IAttributeProps) => {
  return (
    <div
      className={classNames(
        styles.attribute,
        hasGreyBackground && styles.greyBg,
      )}
    >
      {icon ? CLUB_ATTRIBUTE_ICON_MAP[icon] : null}
      <Body>{title}</Body>
    </div>
  );
};
