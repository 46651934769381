import styles from './Contact.module.scss';
import { useIntl } from '@domains/i18n';
import { IClubDetails } from '@features/club-page/types/IClub';
import { Layout } from '@features/shared/components/layout';
import { IGeolocation } from '@models/IGeolocation';

import Icons from 'features/shared/components/icons';
import { Link } from 'features/shared/components/link';
import { SECTIONS_IDS } from '@features/club-page/utils/constants';
import { Section } from '@features/shared/components/layout/section';
import { LogoSmall } from '@features/shared/components/logo/logo-small';
import classNames from 'classnames';
import { Anchor } from '../club-details/anchor-links/anchor';
import { ContactBlock } from './contact-block';
import { ContactMap } from './contact-map/ContactMap';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import { Body, FontColor, H2 } from 'src/features/shared/components/typography';

export interface IContactProps {
  clubLocation: IGeolocation;
  clubDetails: IClubDetails;
  isMobile: boolean;
  isPanel?: boolean;
}

export const Contact = ({
  clubLocation,
  clubDetails,
  isMobile,
  isPanel,
}: IContactProps) => {
  const { formatMessage } = useIntl();

  const contactItems = [
    {
      icon: <Icons.Phone />,
      label: clubDetails.phoneNumber,
      href: `tel:${clubDetails.phoneNumber}`,
    },
    {
      icon: <Icons.Mail />,
      label: formatMessage('club-detail.contact.email.label'),
      href: `mailto:${clubDetails.emailAddress}`,
    },
  ];

  return (
    <Section hasNoBottomMargin={isPanel}>
      <Layout.Container forceMobileView={isPanel}>
        <Layout.Row>
          <Layout.Col>
            <div
              className={classNames(
                styles.container,
                isPanel && styles.isPanel,
              )}
            >
              <Anchor title={SECTIONS_IDS.CONTACT} />
              <ContactMap
                className={classNames(
                  styles.contactMap,
                  isPanel && styles.isPanel,
                )}
                isMobile={isMobile}
                isPanel={isPanel}
                clubLocation={clubLocation}
              />

              <div
                className={classNames(
                  styles.contactCard,
                  isPanel && styles.isPanel,
                )}
              >
                <div className={styles.contactCardContent}>
                  <H2 color={FontColor.Neutral06} forceMobileView={isPanel}>
                    {formatMessage('club-detail.contact.title')}
                  </H2>

                  <ContactBlock
                    title={formatMessage('club-detail.address.title')}
                    isPanel={isPanel}
                  >
                    <Body color={FontColor.Neutral06}>
                      {clubDetails.address}
                    </Body>
                    <Body color={FontColor.Neutral06}>
                      {clubDetails.postalCode} {clubDetails.city}
                    </Body>

                    {!isPanel && (
                      <Link
                        openInNewTab
                        icon={Icons.ArrowRight}
                        href={`https://maps.google.com/?q=SportCity+${clubDetails.address}+${clubDetails.city}`}
                        className={styles.mapsLink}
                        onClick={() => {
                          pushGA4Event(GA4_EVENTS.trackEvent, {
                            event_name: 'click_route_maps_club',
                          });
                        }}
                      >
                        {formatMessage('club-detail.contact.googleMaps')}
                      </Link>
                    )}
                  </ContactBlock>

                  <ContactBlock
                    title={formatMessage('club-detail.contact.title')}
                    isPanel={isPanel}
                  >
                    {contactItems.map(
                      (contact) =>
                        contact.label && (
                          <div
                            className={styles.contactItem}
                            key={contact.href}
                          >
                            {contact.icon}
                            <Link
                              href={contact.href}
                              className={styles.contactLink}
                              onClick={() => {
                                pushGA4Event(GA4_EVENTS.trackEvent, {
                                  event_name: 'sent_email_club',
                                });
                              }}
                            >
                              {contact.label}
                            </Link>
                          </div>
                        ),
                    )}
                  </ContactBlock>
                </div>

                <div className={styles.logoContainer}>
                  <LogoSmall />
                </div>
              </div>
            </div>
          </Layout.Col>
        </Layout.Row>
      </Layout.Container>
    </Section>
  );
};
