import { MembershipPrices } from '@features/pg-funnel/hooks/use-membership-prices'

export type Company = 'sportcitypremium' | 'sportcity'
type Funnel = string
type PaymentPlanId = number

type Prices = {
  [key in Company]: {
    [key: Funnel]: {
      [key: PaymentPlanId]: MembershipPrices
    }
  }
}

export const production: Prices = {
  sportcity: {
    // todo: verify this is the right funnel slug
    mei2022: {
      // telco 2022 1 maand
      4457: {
        pricePerMonth: 59.99,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0
      },
      // telco 2022 6 maanden, betalen per 4 weken
      4458: {
        pricePerMonth: 59.99,
        discountedPricePerMonth: 29.99,
        discountDuration: 2,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0
      },
      // telco 2022 6 maanden, betalen per 6 maanden
      4459: {
        defaultMembershipFee: 391.01,
        discountDuration: 2,
        discountTotal: -65.16,
        membershipFee: 325.85,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0
      },
      // telco 2022 1 jaar, betalen per 4 weken
      4460: {
        pricePerMonth: 59.99,
        discountedPricePerMonth: 29.99,
        discountDuration: 2,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0
      },
      // telco 2022 1 jaar, betalen per jaar
      4461: {
        defaultMembershipFee: 782.01,
        discountDuration: 6,
        discountTotal: -195.47,
        membershipFee: 586.54,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0
      },
      // student membership 1 year
      21: {
        pricePerMonth: 39.99,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0
      }
    },
    'zomersport-2022': {
      6371: {
        membershipFee: 129.99,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0
      }
    },
    'back-to-sport': {
      6882: {
        membershipFee: 129.99,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0,
        followUpPrice: 59.99
      },
      6883: {
        membershipFee: 69.99,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0,
        followUpPrice: 29.99
      }
    }
  },
  sportcitypremium: {}
}

export const staging: Prices = {
  sportcity: {
    // todo: verify this is the right funnel slug
    mei2022: {
      // telco 2022 1 maand
      4456: {
        pricePerMonth: 59.99,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0
      },
      // telco 2022 6 maanden, betalen per 4 weken
      4457: {
        pricePerMonth: 59.99,
        discountedPricePerMonth: 29.99,
        discountDuration: 2,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0
      },
      // telco 2022 6 maanden, betalen per 6 maanden
      4458: {
        defaultMembershipFee: 391.01,
        discountDuration: 2,
        discountTotal: -65.16,
        membershipFee: 325.85,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0
      },
      // telco 2022 1 jaar, betalen per 4 weken
      4454: {
        pricePerMonth: 59.99,
        discountedPricePerMonth: 29.99,
        discountDuration: 2,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0
      },
      // telco 2022 1 jaar, betalen per jaar
      4455: {
        defaultMembershipFee: 782.01,
        discountDuration: 6,
        discountTotal: -195.47,
        membershipFee: 586.54,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0
      },
      // Student membership (1 year)
      4450: {
        pricePerMonth: 39.99,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0
      }
    },
    zomersporten: {
      4471: {
        membershipFee: 129.99,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0
      },
      4478: {
        membershipFee: 69.99,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0
      }
    },
    'back-to-sport': {
      5140: {
        membershipFee: 129.99,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0,
        followUpPrice: 59.99
      },
      5143: {
        membershipFee: 69.99,
        defaultJoiningFee: 39.99,
        joiningFeeDiscount: -39.99,
        joiningFee: 0,
        followUpPrice: 29.99
      }
    }
  },
  sportcitypremium: {}
}

export default {
  production,
  staging
}
