import { useParams } from 'next/navigation';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useRequestState from 'utils/hooks/use-request-state';
import useBoundDispatch from 'utils/redux/hooks/use-bound-dispatch';
import { paymentPlansByIdSelector } from '../../store/selectors';
import * as Actions from './actions';
import * as Selectors from './selectors';

/*
  Returns a contract based on a clubId + paymentPlanId
 */
export function useContractByPaymentPlanId(funnelSlug, clubId, paymentPlan) {
  const doFetchContractByPaymentPlanId = useBoundDispatch(
    Actions.fetchContractByPaymentPlanId,
  );
  const contract = useSelector(
    Selectors.getContractByPaymentPlanId(funnelSlug, paymentPlan?.id),
  );
  const [isLoading, error, { doSetLoading, doSetError }] = useRequestState();

  useEffect(() => {
    if (paymentPlan?.id && !contract) {
      doSetLoading(true);
      doFetchContractByPaymentPlanId(funnelSlug, clubId, paymentPlan)
        .then(() => doSetLoading(false))
        .catch((_error) => doSetError(_error));
    }
  }, [clubId, paymentPlan, funnelSlug]);

  return [contract, isLoading, error];
}

/*
  Returns an array of Contracts based on a ClubId
 */
export function useContractsByClubId(clubId, attributeId) {
  const { slug: funnelSlug } = useParams();
  const doFetchContractsByClubId = useBoundDispatch(
    Actions.fetchContractsByClubId,
  );
  const paymentPlansById = useSelector(paymentPlansByIdSelector(funnelSlug));
  const contractsFromStore = useSelector(
    Selectors.getContractsByFunnelSlug(funnelSlug),
  );

  if (attributeId !== 'commitmentPeriodId') return [];

  const filteredPaymentPlans = paymentPlansById
    ? Object.values(paymentPlansById).filter((plan) =>
        plan.attributes.clubIds?.includes(clubId),
      )
    : [];

  /*
    we check the store for contracts that have been fetched already
   */
  const contractsByPaymentPlanId = filteredPaymentPlans.reduce((acc, plan) => {
    if (!contractsFromStore?.[plan.id]) {
      return acc;
    }

    return {
      ...acc,
      [plan.id]: contractsFromStore[plan.id],
    };
  }, {});

  const [isLoading, error, { doSetLoading, doSetError }] = useRequestState();

  /*
    we fetch the contracts by club id if not all contracts were available already
   */
  useEffect(() => {
    if (
      clubId &&
      (!Object.keys(contractsByPaymentPlanId).length ||
        !Object.keys(contractsByPaymentPlanId).length ===
          filteredPaymentPlans.length)
    ) {
      doSetLoading(true);
      doFetchContractsByClubId(funnelSlug, clubId, paymentPlansById)
        .then(() => doSetLoading(false))
        .catch((_error) => doSetError(_error));
    }
  }, [clubId]);

  return [contractsByPaymentPlanId, isLoading, error];
}
