import styles from './ContactBlock.module.scss';
import { FontColor, H4 } from 'src/features/shared/components/typography';

export interface IContactBlockProps {
  title: string;
  children: React.ReactNode | React.ReactNodeArray;
  isPanel?: boolean;
}

export const ContactBlock = ({
  title,
  children,
  isPanel,
}: IContactBlockProps) => (
  <div className={styles.block}>
    <H4 color={FontColor.Neutral06} forceMobileView={isPanel}>
      {title}
    </H4>

    {children}
  </div>
);
