import moment from 'moment-timezone';
import { Club } from 'src/features/shared/types/Club';

interface ClubIsOpen {
  isOpen: boolean;
  openUntil?: string;
  openFrom?: {
    day: string; // e.g. monday, tuesday, ... || tomorrow (used as keys to get right copy)
    hour: string; // e.g. '07:00'
  } | null;
}

// little helper function to check if there is an exception for a given date
const getOpeningHoursFromDate = (club: Club.Details, date: moment.Moment) => {
  const exception = club.openingHoursExceptions?.find((exception) =>
    moment(exception.date).isSame(date, 'day'),
  );
  const dayString = date.locale('en-gb').format('dddd').toLowerCase();
  return (
    exception || club.openingHours?.find((entry) => entry.day === dayString)
  );
};

const getClubIsOpen = (club: Club.Details): ClubIsOpen => {
  const currentDate = moment().tz('Europe/Amsterdam');
  const currentHour = currentDate.hour();
  const currentOpeningHours = getOpeningHoursFromDate(club, currentDate);
  const [openFrom, openUntil] = currentOpeningHours?.hours.split(' - ') || []; // ['07:00', '22:00']

  /*
      if currently closed, we check if it opens again tomorrow and at what time
   */
  if (currentOpeningHours?.isClosed || currentHour > parseInt(openUntil)) {
    const tomorrowDate = currentDate.add(1, 'days');
    const tomorrowOpeningHours = getOpeningHoursFromDate(club, tomorrowDate);

    if (tomorrowOpeningHours && !tomorrowOpeningHours.isClosed) {
      return {
        isOpen: false,
        openFrom: {
          day: 'tomorrow',
          hour: tomorrowOpeningHours.hours as string,
        },
      };
    }

    /*
      If not open tomorrow either, then simply show that it's closed
     */
    return {
      isOpen: false,
    };
  }

  // if the club is not yet open, but will open later
  if (openFrom && currentHour < parseInt(openFrom)) {
    return {
      isOpen: false,
      openFrom: {
        day: 'today',
        hour: openFrom,
      },
    };
  }

  // if the club is not yet open, but will open later
  if (openFrom && currentHour < parseInt(openFrom)) {
    return {
      isOpen: false,
      openFrom: {
        day: 'today',
        hour: openFrom,
      },
    };
  }

  return {
    isOpen: true,
    openUntil,
  };
};

export default getClubIsOpen;
