import isServer from '@utils/is-server'
import {
  RefObject, useEffect, useMemo, useState
} from 'react'

/**
 * Returns true if passed ref element is in viewport
 */
export function useIsVisible<T extends HTMLElement = HTMLElement> (ref: RefObject<T>) {
  const [ isVisible, setIsVisible ] = useState(false)

  const observer = useMemo(
    () =>
      !isServer
        ? new IntersectionObserver(([ entry ]) => {
            setIsVisible(entry.isIntersecting)
          })
        : null,
    []
  )

  useEffect(() => {
    if (ref.current && observer) {
      observer.observe(ref.current)
    }

    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [ ref, observer ])

  return isVisible
}
