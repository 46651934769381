import { Extra } from 'src/features/checkout/types/Extra';
import { DetailsFields } from 'src/features/checkout/types/details-fields';
import {
  ContractType,
  PaymentPlan,
} from 'src/features/checkout/types/membership';
import { Image } from 'src/features/pg-funnel/services/contentful/types';

export enum CheckoutStep {
  intro = 'intro',
  club = 'club',
  membership = 'membership',
  details = 'details',
  extras = 'extras',
  summary = 'summary',
}

export interface Step {
  id: CheckoutStep;
  index: number;
  enabled: boolean;
  shortTitle: string;
  slug: string;
}

export type StepResponse = Partial<{
  [key in CheckoutStep]: {
    enabled: boolean;
  };
}>;

export interface CheckoutSummary {
  startDate?: string;
  club?: {
    id: number;
    name: string;
    accessLevel: 'value' | 'standard' | 'premium';
  };
  details?: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
  paymentPlan?: PaymentPlan;
  voucherCode?: {
    value: string;
    discount: {
      joiningFee: number;
      price: number;
    };
  };
  extras?: Extra[];
  memberSignup?: {
    newMember: boolean;
  };
}

export interface BaseCheckout {
  id: string;
  status: 'created';
  currentStep: CheckoutStep;
  steps: StepResponse;
  summary: CheckoutSummary;
  errors?: string[];
  options?: CheckoutOptions;
}

export interface CheckoutField<T extends string | number> {
  required: boolean;
  value?: T;
  error?: string;
}

export interface CheckoutOptions {
  dayPass?: boolean;
  contractAutoCancel?: boolean;
}

export interface IntroStepCheckout extends BaseCheckout {
  currentStep: CheckoutStep.intro;
  fields: {
    accessCode?: CheckoutField<string>;
  };
}

export interface ClubStepCheckout extends BaseCheckout {
  currentStep: CheckoutStep.club;
  fields: {
    club: CheckoutField<string>;
  };
}

export interface MembershipStepCheckout extends BaseCheckout {
  currentStep: CheckoutStep.membership;
  contractTypes: ContractType[];
  fields: {
    startDate: CheckoutField<string>;
    paymentPlan: CheckoutField<number>;
  };
}

export interface ExtrasStepCheckout extends BaseCheckout {
  currentStep: CheckoutStep.extras;
  fields: {
    extras: {
      required: boolean;
      value?: number[];
      error?: string;
    };
  };
  extras: Extra[];
}

export interface DetailsStepCheckout extends BaseCheckout {
  currentStep: CheckoutStep.details;
  fields: DetailsFields;
}

export interface SummaryStepCheckout extends BaseCheckout {
  currentStep: CheckoutStep.summary;
  fields?: {
    voucherCode?: CheckoutField<string>;
  };
  summary: CheckoutSummary;
}

export type FailedCheckout = {
  id: string;
  status: 'failed';
  error: {
    code: string;
    message: string;
  } | null;
  options?: CheckoutOptions;
};

export type PaymentPendingCheckout = {
  id: string;
  status: 'payment_pending';
  url: string;
  options?: CheckoutOptions;
};

export type ProcessingCheckout = {
  id: string;
  status: 'processing';
  summary: CheckoutSummary;
  options?: CheckoutOptions;
};

export type CompletedCheckout = {
  id: string;
  status: 'completed';
  summary: CheckoutSummary;
  options?: CheckoutOptions;
};

export type CreatedCheckout =
  | IntroStepCheckout
  | ClubStepCheckout
  | MembershipStepCheckout
  | ExtrasStepCheckout
  | DetailsStepCheckout
  | SummaryStepCheckout;

export type Checkout =
  | CreatedCheckout
  | ProcessingCheckout
  | FailedCheckout
  | PaymentPendingCheckout
  | CompletedCheckout;

/**
 * CheckoutClub is combination of Electrolyte.Club and IClubContentPage
 */
export interface CheckoutClub {
  id: string;
  slug: string;
  pgClubId: number;
  name: string;
  address: string;
  city: string;
  postalCode: string;
  country: string;
  latitude: number;
  longitude: number;
  phoneNumber: string;
  email: string;
  openingHours: OpeningHoursItem[];
  openingHourExceptions: OpeningHoursException[];
  priceFrom: number;
  images?: Image[];
  geoDistanceFromSearch?: number;
}

export interface OpeningHoursItem {
  day: string;
  hours: string;
  isClosed: boolean;
}

export interface OpeningHoursException {
  date: string;
  day: string;
  hours: string;
  isClosed: boolean;
}

export interface CustomCheckoutSettings {
  membership: {
    pageTitle?: string;
    pageDescription?: string;
    hideContractType?: boolean;
    hideCommitmentPeriodLegend?: boolean;
    hidePaymentInterval?: boolean;
    hideStartDate?: boolean;
    hidePriceDescription?: boolean;
  };
  summary: {
    hidePaymentInterval?: boolean;
    hideStartDate?: boolean;
    hideCommitmentPeriod?: boolean;
    showSingularPriceLabel?: boolean;
    hideWidgetFooter?: boolean;
  };
  thankYou: {
    headerDescription?: string;
  };
}

export type CustomCheckoutSettingsGenerator = (
  formatMessage: (v: string) => string,
) => Partial<CustomCheckoutSettings>;
