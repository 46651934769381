import { useIntl } from '@domains/i18n'
import { ContentSections } from '@features/shared/contentful/types/IContentSection'
import { IGenericContentSection } from '@features/shared/contentful/types/IGenericContentSection'
import { convertSpacesToDashCase } from '@features/shared/utils/string-utils'
import { GENERIC_SECTION_TYPES } from '@features/shared/utils/constants'
import { SECTIONS_IDS } from './constants'
import { CONTENT_TYPES } from '../../../../contentful/constants'

export interface ITag {
  label: string;
  id: string;
}

export function getAnchorTag (anchorLinkTitle: string): ITag {
  return {
    label: anchorLinkTitle,
    id: convertSpacesToDashCase(anchorLinkTitle)
  }
}

export function useAnchorLinksTags (contentBlocks?: ContentSections, hasSpecialists?: boolean): {tags: ITag[]} {
  const { formatMessage } = useIntl()
  const tags = [
    { id: SECTIONS_IDS.ABOUT, label: formatMessage('club-detail.about.title') },
    { id: SECTIONS_IDS.OPENING_HOURS, label: formatMessage('club-detail.opening-hours.title') }
  ]
  contentBlocks && contentBlocks.forEach((item) => {
    if (!item.anchorLinkTitle) return

    if (
      item.type === CONTENT_TYPES.GENERIC_CONTENT_SECTION &&
      (item as IGenericContentSection).componentType === GENERIC_SECTION_TYPES.SPECIALISTS &&
      !hasSpecialists) {
      return null
    }

    const tag = getAnchorTag(item.anchorLinkTitle)
    if (tag) {
      tags.push(tag)
    }
  })

  tags.push({ id: SECTIONS_IDS.CONTACT, label: formatMessage('club-detail.anchor-links.contact') })

  return { tags }
}
