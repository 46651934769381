import styles from './index.module.scss';
import Icons from 'features/shared/components/icons';
import { Tooltip } from 'features/shared/components/tooltip';
import { ClubsListUsp } from '@features/pg-funnel/components/content-items/funnel-usp-block/clubs-list-usp';
import { Usp } from '@features/pg-funnel/hooks/use-funnel-config/types';
import classNames from 'classnames';
import { useRichTextRenderer } from 'src/features/shared/hooks/useRichTextRenderer';
import { Body, FontColor } from 'src/features/shared/components/typography';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';
import { isRichText } from '../../contentful/utils/isRichText';

export interface IUspItemProps {
  usp: Usp;
  index: number;
  insertClubUsp?: boolean;
  clubId?: number | null;
  isColorNeutral06?: boolean;
  isColorNeutral01?: boolean;
}

const iconMap = {
  Arrow: Icons.ArrowRight,
  Plus: Icons.Plus,
  Cross: Icons.Cross,
  Checkmark: Icons.Checkmark,
};

export function UspItem({
  usp,
  index,
  insertClubUsp,
  clubId,
  isColorNeutral06 = false,
}: IUspItemProps) {
  const { renderRichText } = useRichTextRenderer();

  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;

  const description = isRichText(usp.description)
    ? renderRichText(usp.description)
    : usp.description;
  const body = description && (
    <Body
      component="div"
      className={styles.uspBody}
      color={
        isColorNeutral06
          ? FontColor.Neutral06
          : usp.isGreyedOut
          ? FontColor.Neutral03
          : FontColor.Neutral01
      }
    >
      <>{description}</>
    </Body>
  );

  const Icon = usp.icon
    ? iconMap[usp.icon] || Icons.Checkmark
    : Icons.Checkmark;
  return (
    <>
      <li
        key={usp.id}
        className={classNames(styles['list-item'], {
          [styles.tag]: isMobile && usp.isTag,
        })}
      >
        <Icon
          className={classNames(styles.icon, {
            [styles.grey]: usp.isGreyedOut,
          })}
        />
        <div className={classNames(styles.usp)}>
          {usp.tooltip ? (
            <>
              {body}
              <Tooltip
                title={usp.tooltip.title}
                description={usp.tooltip.description}
              >
                <Icons.InfoCircleFilled />
              </Tooltip>
            </>
          ) : (
            body
          )}
        </div>
      </li>
      {insertClubUsp && clubId && index === 0 ? (
        <li className={styles['list-item']}>
          <ClubsListUsp
            isColorNeutral02={!isMobile}
            clubId={clubId}
            isTertiary
          />
        </li>
      ) : null}
    </>
  );
}
