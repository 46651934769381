import { ReactNode } from 'react';
import styles from './index.module.scss';
import useBreakpoints from '@utils/hooks/use-breakpoints';
import classNames from 'classnames';
import { Body, Large } from 'src/features/shared/components/typography';

interface IDescriptionTypographyProps {
  children: ReactNode;
  className?: string;
}

export function DescriptionTypography({
  children,
  className = '',
}: IDescriptionTypographyProps) {
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const Typography = currentBreakpoint === breakpoints.xs.name ? Body : Large;

  return (
    <Typography
      component="div"
      className={classNames(styles.description, className)}
    >
      <>{children}</>
    </Typography>
  );
}
