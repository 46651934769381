import classNames from 'classnames'
import styles from './index.module.scss'
import { ComponentProps } from 'react'

type fullscreenContainerProps = ComponentProps<'div'>

export function FullscreenContainer ({
  children,
  className,
  ...props
}: fullscreenContainerProps) {
  return (
    <div className={classNames(styles.fullscreen, className)} {...props}>
      {children}
    </div>
  )
}
