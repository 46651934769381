import isServer from '@utils/is-server';
import { useCallback } from 'react';
import { EnrichedPaymentPlan } from '@features/pg-funnel/services/perfect-gym/mappers/map-payment-plans';
import { FormValues } from '@features/pg-funnel/types';
import { Voucher } from '@features/pg-funnel/services/perfect-gym/mappers/map-voucher';
import theme from '@config/theme';
import { ContractWithPaymentPlanId } from '@features/pg-funnel/services/perfect-gym/types';
import { isProduction } from '@features/shared/utils/is-production';

export default function useDataLayer() {
  if (isServer || !isProduction()) return {};
  window.dataLayer = window.dataLayer || [];

  /*
    Initial implementation to make sure some payment plan data is sent to GA when a user is checking out.
    Documentation: https://developers.google.com/analytics/devguides/collection/ga4/reference/events#begin_checkout
   */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const pushToDataLayerOnSubmit = useCallback(
    (
      funnelSlug: string,
      formValues: FormValues,
      selectedPaymentPlan: EnrichedPaymentPlan,
      selectedContract: ContractWithPaymentPlanId,
      voucher?: Voucher | null,
    ) => {
      // total price including discounts from Voucher or DiscountIds
      const price = voucher
        ? voucher.summary.membership.total
        : selectedContract.charges.reduce((acc, { amount }) => acc + amount, 0);

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
          items: [
            {
              item_id: selectedPaymentPlan.id, // 5145
              item_name: selectedPaymentPlan.name, // "SC CityFit 2022 1 jaar (59,99)"
              affiliation: `Online Store - Funnel - ${funnelSlug}`, // "back-to-sport"
              coupon: voucher?.summary.voucher.code, // d7H5WPQ
              currency: 'EUR',
              discount: voucher?.summary.voucher.amount, // 19.99
              item_brand: theme.name, // SportCity
              item_category: formValues.commitmentPeriodId, // "12-months-0-days"
              item_category2: formValues.paymentIntervalId, // "0-months-28-days"
              location_id: formValues.clubId, // 53
              price, // 29.99
              quantity: 1,
            },
          ],
        },
      });
    },
    [window.dataLayer],
  );

  return {
    pushToDataLayerOnSubmit,
  };
}
