import { Image } from '../../image'

export interface ILogoSmallProps {
  className?: string;
}

export const LogoSmall = ({ className }: ILogoSmallProps) => (
  <Image
    className={className}
    image={{
      alt: 'SportCity logo',
      src: '/static/images/sportcity/illustrations/fallback-thumb.svg',
      srcSet: '/static/images/sportcity/illustrations/fallback-thumb.svg'
    }}
  />
)
