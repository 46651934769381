import { IFaqSection } from 'src/features/shared/contentful/types/IFaqSection';
import { useRichTextRenderer } from 'src/features/shared/hooks/useRichTextRenderer';
import { Button } from '../../button';
import { Col, Container, Row, Section } from '../../layout';
import * as Tabs from '../../tabs';
import { H2, Large } from '../../typography';
import styles from './FaqSection.module.scss';
import { FaqList } from './components/faq-list';
import { useState } from 'react';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';

function getTabValue(index: number) {
  return `tab-${index}`;
}

export function FaqSection({
  title,
  description,
  categories,
  button,
}: IFaqSection) {
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;
  const [activeTab, setActiveTab] = useState(getTabValue(0));
  const { renderSectionDescription } = useRichTextRenderer();

  return (
    <Section>
      <Container>
        <Row className={styles.row}>
          <Col columns="xs:12 md:8" offset="md:2">
            <H2 className={styles.title}>{title}</H2>
            {description && (
              <Large className={styles.description}>
                {renderSectionDescription(description)}
              </Large>
            )}
          </Col>
        </Row>
        <Tabs.Root value={activeTab} onValueChange={setActiveTab}>
          <Row className={styles.row}>
            <Col>
              <Tabs.List
                variant={isMobile ? 'dropdown' : 'default'}
                value={activeTab}
                items={categories.map((category, index) => ({
                  value: getTabValue(index),
                  label: category.title,
                }))}
                onValueChange={setActiveTab}
              />
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col columns="xs:12 md:8" offset="md:2">
              {categories.map((category, index) => (
                <Tabs.Content
                  key={index}
                  className="TabsContent"
                  value={getTabValue(index)}
                  tabIndex={-1}
                >
                  <FaqList items={category.items} />
                </Tabs.Content>
              ))}
            </Col>
          </Row>
        </Tabs.Root>
        {button && (
          <Row className={styles.row}>
            <Col className={styles.footer}>
              <Button href={button.href} variant={ButtonVariant.Inverted}>
                {button.label}
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </Section>
  );
}
