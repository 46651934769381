import classNames from 'classnames';

import { ElementType, Fragment } from 'react';
import styles from './PageTitle.module.scss';

export interface IPageTitleProps {
  element?: ElementType;
  isLight?: boolean;
  title: string;
  className?: string;
}

export const PageTitle = ({
  element: Element = 'h1',
  isLight,
  title,
  className,
}: IPageTitleProps) => {
  const pattern = /([^*]*)\*(.*?)\*([^*]*)/g;
  const matches = [];
  let match;

  while ((match = pattern.exec(title)) !== null) {
    matches.push({
      textBefore: match[1],
      highlightedText: match[2],
      textAfter: match[3] || '',
    });
  }

  return (
    <Element
      className={classNames(
        styles.pageTitle,
        { [styles.light]: isLight },
        className,
      )}
    >
      {matches.length
        ? matches.map((match, index) => (
            <Fragment key={index}>
              {match.textBefore}
              <span className={styles.highlightedText}>
                {match.highlightedText}
              </span>
              {match.textAfter}
            </Fragment>
          ))
        : title}
    </Element>
  );
};
