import { RefObject, useState } from 'react'

export function useDragToScroll (ref: RefObject<HTMLDivElement>) {
  const [ isScrolling, setIsScrolling ] = useState(false)
  const [ scrollPosition, setScrollPosition ] = useState({ left: 0, x: 0 })

  const handleMouseDown = (e: React.MouseEvent) => {
    if (ref.current) {
      setIsScrolling(true)
      setScrollPosition({ left: ref.current?.scrollLeft ?? 0, x: e.clientX })

      ref.current.style.cursor = 'grabbing'
      ref.current.style.userSelect = 'none'
    }
  }

  const handleMouseUp = () => {
    if (ref.current) {
      setIsScrolling(false)

      ref.current.style.cursor = 'grab'
      ref.current.style.removeProperty('user-select')
    }
  }

  const handleMouseMove = (e: React.MouseEvent) => {
    if (isScrolling && window && ref.current) {
      const dX = e.clientX - scrollPosition.x

      ref.current.scrollLeft = scrollPosition.left - dX
      setScrollPosition({
        left: scrollPosition.left - dX,
        x: e.clientX
      })
    }
  }

  return { handleMouseDown, handleMouseUp, handleMouseMove }
}
