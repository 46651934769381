'use client';
import styles from './BreadcrumbNavigation.module.scss';
import { Container } from '@components/layout';
import theme from '@config/theme';
import { useIntl } from '@domains/i18n';
import Icons from 'features/shared/components/icons';
import { Link } from 'features/shared/components/link';
import { useParams } from 'next/navigation';
import { Body } from 'src/features/shared/components/typography';
import { Club } from 'src/features/shared/types/Club';
import { getSlugFromText } from 'src/features/shared/utils/get-slug-from-text';
import { getTitleFromSlug } from 'src/features/shared/utils/string-utils';
import { getShortClubName } from '../../utils/getShortClubName';

export function BreadcrumbNavigation({ club }: { club: Club.Details }) {
  const {
    slug: [slug, extraSlug],
  } = useParams<{ slug: string[] }>();

  const { formatMessage } = useIntl();

  const city = getTitleFromSlug(slug || '') || undefined;
  const clubName = extraSlug ? getShortClubName(club) : undefined;

  return (
    <Container className={styles.container}>
      <div className={styles.menu}>
        <Link href={`/${theme.clubs.slug}`} icon={Icons.ChevronDown}>
          {formatMessage('general.country')}
        </Link>

        {city && clubName && (
          <>
            <Link href={`/${getSlugFromText(city)}`} icon={Icons.ChevronDown}>
              {city}
            </Link>
            <Body className={styles.crumb}>{clubName}</Body>
          </>
        )}

        {city && !clubName && <Body className={styles.crumb}>{city}</Body>}
      </div>
    </Container>
  );
}
