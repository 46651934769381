import Icons from '@features/shared/components/icons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import classNames from 'classnames';
import { ReactNode, forwardRef } from 'react';
import styles from './Tabs.module.scss';

interface RootProps extends TabsPrimitive.TabsProps {
  value: string | undefined;
  onValueChange: (value: string) => void;
}

export const Root = (props: RootProps) => <TabsPrimitive.Root {...props} />;

interface ListProps extends Omit<TabsPrimitive.TabsListProps, 'children'> {
  variant?: 'default' | 'dropdown';
  value: string | undefined;
  onValueChange: (value: string) => void;
  items: {
    value: string;
    label: ReactNode;
  }[];
}

export const List = ({
  className,
  items,
  variant = 'default',
  ...props
}: ListProps) => {
  if (variant === 'default') {
    return (
      <TabsPrimitive.List
        className={classNames(styles.list, className)}
        {...props}
      >
        {items.map((item) => (
          <Trigger key={item.value} value={item.value}>
            {item.label}
          </Trigger>
        ))}
      </TabsPrimitive.List>
    );
  }

  return (
    <TabsPrimitive.List
      className={classNames(styles.list, styles.dropdownList, className)}
      {...props}
      tabIndex={-1}
    >
      <DropdownMenu.Root>
        <DropdownMenu.Trigger
          className={classNames(
            styles.trigger,
            styles.dropdownTrigger,
            className,
          )}
        >
          {items.map((item) => (
            <TabsPrimitive.Trigger
              key={item.value}
              value={item.value}
              className={styles.dropdownTriggerItem}
              asChild
            >
              <span tabIndex={-1}>{item.label}</span>
            </TabsPrimitive.Trigger>
          ))}

          <Icons.CaretDown className={styles.dropdownIcon} />
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content className={styles.dropdownContent}>
            <div>
              {items.map((item) => (
                <DropdownMenu.Item
                  key={item.value}
                  className={styles.dropdownItem}
                  onClick={() => props.onValueChange?.(item.value)}
                  aria-selected={props.value === item.value}
                >
                  <Icons.Checkmark className={styles.dropdownIcon} />
                  <span>{item.label}</span>
                </DropdownMenu.Item>
              ))}
            </div>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </TabsPrimitive.List>
  );
};

export const Trigger = forwardRef<
  HTMLButtonElement,
  TabsPrimitive.TabsTriggerProps
>(({ className, ...props }, ref) => {
  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={classNames(styles.trigger, className)}
      {...props}
    />
  );
});

Trigger.displayName = 'Tabs.Trigger';

export const Content = ({
  className,
  ...props
}: TabsPrimitive.TabsContentProps) => {
  return (
    <TabsPrimitive.Content
      className={classNames(styles.content, className)}
      {...props}
    />
  );
};
