import { Club } from 'src/features/shared/types/Club';

/**
 * Transforms club name and city into a short name
 * e.g.
 * "Amsterdam Lijnbaansgracht" -> "Lijnbaansgracht"
 * "Amsterdam Amsterdamsestraat" -> "Amsterdamsestraat"
 */
export function getShortClubName({ city, name }: Club.Details) {
  return name.replace(city, '').trim();
}
