import {
  useEffect, useState, RefObject
} from 'react'

/**
 * Check when component is visible in the viewport.
 */
const useIntersection = (element: RefObject<Element>, rootMargin = '0px') => {
  const [ isVisible, setState ] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([ entry ]) => {
        setState(entry.isIntersecting)
      }, { rootMargin }
    )

    element.current && observer.observe(element.current)

    return () => {
      element.current && observer.unobserve(element.current)
    }
  }, [])

  return isVisible
}

export default useIntersection
