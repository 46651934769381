import { createSelector } from 'reselect'
import funnelSlice, { FunnelState } from '@features/pg-funnel/store/slice'

interface RootState {
  [funnelSlice.name]: FunnelState
}

// todo: check if we can pass funnelSlug into here
const funnelSelector = (state: RootState) => state[funnelSlice.name]

export const clubsByIdSelector = (funnelSlug: string) => createSelector(
  funnelSelector,
  (state) => state.clubsById[funnelSlug]
)

export const paymentPlansByIdSelector = (funnelSlug: string) => createSelector(
  funnelSelector,
  (state) => state.paymentPlansById[funnelSlug]
)

export const selectedPaymentPlanSelector = (funnelSlug: string) => createSelector(
  funnelSelector,
  (state) => state.selectedPaymentPlan[funnelSlug]
)

export const funnelConfigBySlugSelector = (funnelSlug: string) => createSelector(
  funnelSelector,
  (state) => state.funnelConfig[funnelSlug]
)

export const contractsByFunnelSlugSelector = (funnelSlug: string) => createSelector(
  funnelSelector,
  (state) => state.contractsByPaymentPlanId[funnelSlug]
)

export const contractSelector = (funnelSlug: string, paymentPlanId: string) => createSelector(
  funnelSelector,
  (state) => state.contractsByPaymentPlanId[funnelSlug].contracts[paymentPlanId]
)

export const filteredPaymentPlansSelector = (funnelSlug: string) => createSelector(
  funnelSelector,
  (state) => state.filteredPaymentPlans[funnelSlug]
)

export const activeVoucherSelector = (funnelSlug: string) => createSelector(
  funnelSelector,
  (state) => state.activeVoucher[funnelSlug]
)
