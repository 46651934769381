import styles from './MapControl.module.scss';
import {
  ControlPosition,
  MapControl as GoogleMapControl,
  useMap,
} from '@vis.gl/react-google-maps';
import classNames from 'classnames';
import Icons from 'features/shared/components/icons';
import { useIntl } from 'src/domains/i18n';

export interface IZoomControlProps {
  userLocation?: { lat: number; lng: number };
  onEnableUserLocation?: () => void;
}

export const MapControl = ({
  onEnableUserLocation,
  userLocation,
}: IZoomControlProps) => {
  const { formatMessage } = useIntl();

  const map = useMap();
  const handleZoomChange = (delta: number) => {
    const currentZoom = map?.getZoom();

    if (currentZoom == null) {
      return;
    }

    const newZoom = currentZoom + delta;
    map?.setZoom(newZoom);
  };

  const handleEnableUserLocation = () => {
    onEnableUserLocation?.();

    if (userLocation) {
      map?.panTo(userLocation);
    }
  };

  return (
    <GoogleMapControl position={ControlPosition.TOP_RIGHT}>
      <div className={styles.container}>
        {onEnableUserLocation && (
          <button
            type="button"
            className={styles.controlButton}
            onClick={handleEnableUserLocation}
            title={formatMessage('map.enable-user-location')}
          >
            <Icons.Location className={styles.icon} />
          </button>
        )}

        <button
          type="button"
          className={classNames(styles.controlButton, styles.mobileHidden)}
          onClick={() => handleZoomChange(1)}
          title={formatMessage('map.increase-zoom')}
        >
          <Icons.Plus className={styles.icon} />
        </button>
        <button
          type="button"
          className={classNames(styles.controlButton, styles.mobileHidden)}
          onClick={() => handleZoomChange(-1)}
          title={formatMessage('map.decrease-zoom')}
        >
          <Icons.Minus className={styles.icon} />
        </button>
      </div>
    </GoogleMapControl>
  );
};
