import * as Accordion from 'src/features/shared/components/accordion';
import { H4 } from 'src/features/shared/components/typography';
import { IFaq } from 'src/features/shared/contentful/types/IFaq';
import { useRichTextRenderer } from 'src/features/shared/hooks/useRichTextRenderer';
import styles from './FaqList.module.scss';

interface FaqListProps {
  items: IFaq[];
}

export function FaqList({ items }: FaqListProps) {
  const { renderRichText } = useRichTextRenderer();
  return (
    <Accordion.Root className={styles.accordion} type="single" collapsible>
      {items.map((item, key) => (
        <Accordion.Item
          key={item.question + key}
          value={item.question + key}
          className={styles.item}
        >
          <Accordion.Trigger className={styles.trigger}>
            <H4>{item.question}</H4>
            <Accordion.Indicator className={styles.indicator} />
          </Accordion.Trigger>
          <Accordion.Content>
            <div className={styles.content}>
              <>{renderRichText(item.answer)}</>
            </div>
          </Accordion.Content>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  );
}
